export default {
    // 登录
    // doLogin(state) {
    //     state.login = true;
    // },
    // // 退出
    // doLogout(state) {
    //     state.login = false;
    // },

    changeLogin(state, bool) {
        // 修改共享number变量的方法
        state.isLogin = bool
      },
    set_userInfo(state,data){
      state.userInfo = data
      // localStorage.setItem('userInfo',JSON.stringify(data))
    },
    set_swiperImg(state,data){
      state.swiperImg = data
      // console.log(state.swiperImg,'state.swiperImg');
      state.swiperImg = data
      localStorage.setItem('homeBanner',JSON.stringify(data))
      // debugger
    },
    // 公告列表
    set_onCollectListX(state,data){
      state.onCollectListX = data
      // debugger
    }
}