import axios from 'axios'
import { getToken } from '@/store/token'
export function request(config) {
  // 1.创建axios的实例
  const instance = axios.create({
    baseURL: 'https://hechizhiliwebapi.zhimanfen.com',
    timeout: 5000,
  })
  // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
  // 2.axios的拦截器
  // 2.1.请求拦截的作用   request 拦截器

  instance.interceptors.request.use(config => {
    // config.headers['Content-Type'] ='application/json;charset=UTF-8'
    const token = getToken();
    if (token) {
      
      config.headers.Authorization = token;
    }
    return config
  }, err => {
    console.log(err);
  })
  // 2.2.响应拦截 response 拦截器

  instance.interceptors.response.use(res => {
    
    return res.data
  }, err => {
    console.log(err);
  })

  // 3.发送真正的网络请求
  return instance(config)
}

