// import Vue from 'vue'
// import Vuex from 'vuex'
// Vue.use(Vuex);
// // 用Vuex.Store对象用来记录token
// const store = new Vuex.Store({

//   state: {
//     // 存储token
//     token: "",
//     userName: "" // 可选
//   },
//   getters: {
//     getToken(state) {
//       return state.token || localStorage.getItem("token") || "";
//     }
//   },
//   mutations: {
//     // 修改token，并将token存入localStorage
//     setToken(state, token) {
//       state.token = token;
//       localStorage.setItem('token', accessToken);
//       console.log('store、localstorage保存token成功！');
//     },
//     delToken(state) {
//       state.token = "";
//       localStorage.removeItem("token");
//     },
//     // 可选
//     setUserInfo(state, userName) {
//       state.userName = userName;
//     }
//   },

//   actions: {
//     // removeToken: (context) => {
//     // context.commit('setToken')
//     // }
//   },
// });

// export default store;



// import Vue from 'vue'
// import Vuex from 'vuex'
// import { setItem, getItem } from '@/utils/storage.js'
// Vue.use(Vuex)
// export default new Vuex.Store({
//   state: {
//     // 保存公共数据 在设置vuex中的初值时，先从本地存储中取，如果取不到，则初始为空
//     tokenInfo: getItem('tokenInfo') || {}
//   },
//   mutations: {
//     mSetTokenInfo(state, tokenObj) {
//       state.tokenInfo = tokenObj
//       // 因为刷新会丢失所以进行持久化 调用storage文件里方法
//       setItem('tokenInfo', tokenObj)
//     }
//   },
//   actions: {
//   },
//   modules: {
//   }
// })



import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

Vue.use(Vuex)

const store =  new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
})

export default store