import { request } from "../utils/request";

const urlAbout = '/api/open'
const urlNews = '/api/information/news'
const urlCompany = '/api/information/notice'
const urlPolicies = '/api/information/policy'
const urlfetchSafety = '/api/information/safety'
const urlfetchDanger = '/api/information/danger'
const urlfetchStrategy = '/api/information/strategy'
const urlleaveMessage = '/api/information/message'
const configurl = '/api/index/index'


// 关于我们
export function fetchAboutUs(data) {
    return request({
        url: urlAbout,
        method: 'post',
        data
    })
}
// 请求版本号（web端做生成二维码请求）
export function appVerison(data) {
    return request({
        url: urlAbout,
        method: 'post',
        data
    })
}
// 新闻中心
export function fetchNewsPageList(data) {
    return request({
        url: urlNews,
        method: 'post',
        data
    })
}
// 新闻详情
export function fetchNewsDetail(data) {
    return request({
        url: urlNews,
        method: 'post',
        data
    })
}
// 公司公告
export function fetchNoticePageList(data) {
    return request({
        url: urlCompany,
        method: 'post',
        data
    })
}
// 公司公告详情
export function fetchNoticeDetail(data) {
    return request({
        url: urlCompany,
        method: 'post',
        data
    })
}
// 政策法规
export function fetchPolicyPageList(data) {
    return request({
        url: urlPolicies,
        method: 'post',
        data
    })
}
// 政策法规详情
export function fetchPolicyDetail(data) {
    return request({
        url: urlPolicies,
        method: 'post',
        data
    })
}
// 安全教育
export function fetchSafetyPageList(data) {
    return request({
        url: urlfetchSafety,
        method: 'post',
        data
    })
}
// 安全教育详情
export function fetchSafetyDetail(data) {
    return request({
        url: urlfetchSafety,
        method: 'post',
        data
    })
}
// 危运百科
export function fetchDangerPageList(data) {
    return request({
        url: urlfetchDanger,
        method: 'post',
        data
    })
}
// 危运百科详情
export function fetchDangerDetail(data) {
    return request({
        url: urlfetchDanger,
        method: 'post',
        data
    })
}
// 行车攻略
export function fetchStrategyPageList(data) {
    return request({
        url: urlfetchStrategy,
        method: 'post',
        data
    })
}
// 危运百科详情
export function fetchStrategyDetail(data) {
    return request({
        url: urlfetchStrategy,
        method: 'post',
        data
    })
}

// 留言提交
export function fetchLeaveMessage(data) {
    return request({
        url: urlleaveMessage,
        method: 'post',
        data
    })
}

// 轮播获取
export function fetchIndexData(data) {
    return request({
        url: configurl,
        method: 'post',
        data
    })
}
