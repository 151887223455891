<template>
  <div id="app">
    <RouterView />
  </div>
</template>

<script>
// import { RouterView } from "vue-router";
export default {
  name: "App",
  data(){
    return {
      // current:''
    }
  },
  // components: {
  //   RouterView,
  // },
  // mounted() {
  //   window.addEventListener("scroll", this.scrolling);
  // },
  // methods:{
  //   // scrolling() {
  //   //   this.current  = document.documentElement.scrollTop || document.body.scrollTop;
  //   //   console.log("this.current",this.current);
  //   //   localStorage.setItem('scrollTopValue',this.current)
  //   // },
  // }
};
</script>

<style>
@import '@/assets/css/base.css'
</style>
