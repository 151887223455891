export default {
    currenUser:null,
    isLogin:false, // 用来存储是否登录状态
    token:'',
    selectedcar:'',
    
    userInfo:JSON.parse(localStorage.getItem('userInfo')) || '',

    swiperImg:JSON.parse(localStorage.getItem('homeBanner')) || [],
    // currenUser = state => state.currenUser
    onCollectListX:{}
}