import { accountLogin, accountRegister } from "@/network/loginAndReg";
// import ElementUI from "element-ui";
import { setToken } from '@/store/token'
import { Icon, Message } from 'element-ui';
import router from "../router";
// import {doLogin} from './mutations'
import { fetchAboutUs, fetchNoticePageList, fetchPolicyPageList, fetchSafetyPageList, fetchStrategyPageList, fetchIndexData } from '@/network/home'



export default {
    // 账号登录
    // async accountLogin(state, data) {
    //     // console.log(loginForm);
    //     const args = {
    //         method: "accountLogin",
    //         params: {
    //             account: data.account,
    //             password: data.password
    //         }
    //     }
    //     const res = await accountLogin(args)
    //     if (res.status == 'failure') {
    //         data.account = ''
    //         data.password = ''
    //         return Message({
    //             showClose: true,
    //             message: res.msg,
    //             type:res.status
    //         })
    //     } else {
    //         Message({
    //             showClose: true,
    //             message: res.msg,
    //             type:res.status
    //         })
    //         // localStorage.setItem('token', res.data.accessToken)
    //         setToken(res.data.accessToken)

    //         switch(res.data.userInfo.user_type[0]){
    //             case "1":
    //                 res.data.userInfo.user_currentType_text = "司机"
    //                 break
    //             case "2":
    //                 res.data.userInfo.user_currentType_text = "押运员"
    //                 break
    //             case "3":
    //                 res.data.userInfo.user_currentType_text = "老板"
    //                 break
    //         }

    //         localStorage.setItem('username',res.data.userInfo.username)

    //         // console.log(res.data.userInfo.username,7777777777)
    //         // this.$store.commit('changeLogin', true) // 修改登录状态
    //         router.push('/index').catch(err => { })
    //     }
    // },
    // 手机号登录

    //注册
    async accountRegister(state, data) {
        const args = {
            method: "accountRegister",
            params: {
                username: data.account,
                user_type: data.checkList.join(','),
                password: data.password,
                password_confirm: data.password_again,
                mobile: data.mobilePhoneNumber,
                mobile_captcha: data.securityCode.toString()
            }
        }
        // console.log(data);
        const res = await accountRegister(args)
        if (res.status == 'failure') {
            // data.securityCodeTwo = ''
            return Message({
                message: res.msg,
                type: res.status
            })
        } else {
            Message({
                message: res.msg,
                type: res.status
            })
            setToken(res.data.accessToken)
            router.push('/login').catch(err => { })

        }
    },

    saveUserInfo({ commit }, data) {
        commit('set_userInfo', data)
    },
    // ...mapActions(['getSwiperImg']),

    async getSwiperImg({ commit, state }) {
        try {
            // console.log('获取轮播图');
            const res = await fetchIndexData({ "method": "fetchIndexData" })
            // debugger

            commit('set_swiperImg', res.data.homeBanner)
            // console.log(res);

        } catch (e) {

        }
    },
    //列表公告api
    async get_onCollectListX({commit},pages) {
        // console.log(pages,'page');
        const args = {
            method: "fetchNoticePageList",
            page: {
                perPage: 5,
                page: pages
            },
        }
        const {
            data: {
                noticePageList
            }
        } = await fetchNoticePageList(args)
        commit('set_onCollectListX',noticePageList)
        // console.log('公告',noticePageList);
        // debugger
        // await fetchNoticePageList(args)
        // async getnoticeList() {
        //     const args = {
        //         method: "fetchNoticePageList",
        //         page: this.page
        //     }
        //     const {
        //         data: {
        //             noticePageList
        //         }
        //     } = await fetchNoticePageList(args)

        //     this.noticePageList = noticePageList
        //     this.total = noticePageList.total;  //数据总量
        //     // console.log(this.noticePageList.data);
        //     // console.log(this.noticePageList.data.is_collect);
        //     // if(this.noticePageList.data.is_collect){

        //     // }
        // },
    }
}
