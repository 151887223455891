import Vue from "vue";
import App from "./App.vue";
import axios from 'axios'
//引入vuerouter
import VueRouter from "vue-router";
import router from "./router";
// 引入elementui组件库
import ElementUI from "element-ui";
// vuex
import store from './store'
// 引入css样式
import "element-ui/lib/theme-chalk/index.css";
import "./assets/iconfont.css";
import "./assets/base.less";

//引入VideoPlayer
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'

Vue.use(VideoPlayer)


// router.beforeEach((to,form,next)=>{
//   if(to.path == '/aaa'){
//     alert("您需要先登录")
//   }
// })
// router.beforeEach((to, from, next) => {
//   /* 路由发生变化修改页面title */
//   if (to.meta.title) {
//     document.title = to.meta.title + '-河池志力运输官网';
//   }
//   next();
// }); 


//应用插件
Vue.use(VueRouter);
axios.defaults.baseURL = '/api'
Vue.use(ElementUI);
Vue.use(VideoPlayer)
new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
