import { request } from "../utils/request";

const urlLoginAndReg = '/api/auth'
const urlAuthCode = '/api/captcha-verify'

// 账号登录
export function accountLogin(data) {
    return request({
        url: urlLoginAndReg,
        method: 'post',
        data
    })
}
// 手机号登录
export function mobileLogin(data) {
    return request({
        url: urlLoginAndReg,
        method: 'post',
        data
    })
}
// 忘记密码找回
export function updatePassword(data) {
    return request({
        url: urlLoginAndReg,
        method: 'post',
        data
    })
}


//注册
export function accountRegister(data) {
    return request({
        url: urlLoginAndReg,
        method: 'post',
        data
    })
}
//验证码
export function authCode() {
    return request({
        url: urlAuthCode,
        method: 'get',    
    })
}


//手机验证码
export function fetchCode(data) {
    return request({
        url: urlLoginAndReg,
        method: 'post',
        data
    })
}
