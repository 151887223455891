const TOKENKEY = 'accessToken'
// 存储
export function setToken(token){
    return localStorage.setItem(TOKENKEY,token)
}
// 获取
export function getToken(){
    return localStorage.getItem(TOKENKEY)
}
// 移除
export function removeToken(){
    return localStorage.removeItem(TOKENKEY)
}