import Vue from 'vue'
import Router from "vue-router";
import { Message } from 'element-ui';
import { getToken } from '@/store/token'
Vue.use(Router)
const routes = [

  { path: '/', redirect: '/index', name: 'index', component: () => import('@/views/officialWebsite/home') },
  // 官网展示页面
  {
    path: '/index', name: 'layout', component: () => import('@/views/layout'), children: [
      {
        path: '/index', meta: { title: '首页', }, component: () => import('@/views/officialWebsite/home'),
      },
      {
        path: '/companyCircular', meta: { title: '公司公告' }, component: () => import('@/views/officialWebsite/companyCircular')
      },
      {
        path: '/pressCenter', meta: { title: '新闻中心' }, component: () => import('@/views/officialWebsite/pressCenter')
      },
      {
        path: '/policiesAndRegulations', meta: { title: '政策法规' }, component: () => import('@/views/officialWebsite/policiesAndRegulations')
      },
      {
        path: '/information', meta: { title: '安全学习' }, component: () => import('@/views/userManagement')
      },
      {
        path: '/safetyEducation', meta: { title: '安全教育' }, component: () => import('@/views/officialWebsite/safetyEducation')
      },
      {
        path: '/encyclopedia', meta: { title: '危运百科' }, component: () => import('@/views/officialWebsite/encyclopedia')
      },
      {
        path: '/drivingGuideline', meta: { title: '行车攻略' }, component: () => import('@/views/officialWebsite/drivingGuideline')
      },
      {
        path: '/leaveAMessage', meta: { title: '留言薄' }, component: () => import('@/views/officialWebsite/leaveAMessage')
      },
      // 公司公告详情
      {
        path: '/companyCircular/companyCirculardDetails', meta: { title: '公司公告详情', }, component: () => import('@/views/officialWebsite/companyCircular/companyCirculardDetails'),
      },
      // 新闻详情
      {
        path: '/pressCenter/pressCenterDetails', meta: { title: '新闻详情', }, component: () => import('@/views/officialWebsite/pressCenter/pressCenterDetails'),
      },
      // 政策法规详情
      {
        path: '/policiesAndRegulations/policiesAndRegulationsDetail', meta: { title: '政策法规详情', }, component: () => import('@/views/officialWebsite/policiesAndRegulations/policiesAndRegulationsDetail'),
      },
      // 安全教育详情
      {
        path: '/safetyEducation/safetyEducationDetail', meta: { title: '安全教育详情', }, component: () => import('@/views/officialWebsite/safetyEducation/safetyEducationDetail'),
      },
      // // 危运百科详情
      {
        path: '/encyclopedia/encyclopediaDetail', meta: { title: '危运百科详情', }, component: () => import('@/views/officialWebsite/encyclopedia/encyclopediaDetail'),
      },
      // // 行车攻略详情
      {
        path: '/drivingGuideline/drivingGuidelineDetail', meta: { title: '行车攻略详情', }, component: () => import('@/views/officialWebsite/drivingGuideline/drivingGuidelineDetail'),
      },
    ]
  },


  { path: '/findpsd', meta: { title: '找回密码' }, component: () => import('@/views/loginAndReg/retrievePassword/index.vue') },
  { path: '/sss', meta: { title: '找回密码' }, component: () => import('@/components/swiper') },
  {
    path: '/webHeader', meta: { title: '头部测试' }, component: () => import('@/components/webHeader')
  },
  // 用户管理页面

  {
    path: '/userManagement', redirect: '/userManagement/myCollect', component: () => import('@/views/userManagement'), children: [
      {
        path: '/userManagement/myCollect', meta: { title: '我的收藏' }, component: () => import('@/views/panelInformation/myCollect.vue')
      },
      {
        path: '/userManagement/record', meta: { title: '考试记录' }, component: () => import('@/views/panelInformation/record.vue')
      },
      {
        path: '/userManagement/account/userData', meta: { title: '账户信息' }, component: () => import('@/views/panelInformation/account/account.vue'), children: [
          {
            path: '/userManagement/account/userData', meta: { title: '用户资料' }, component: () => import('@/views/panelInformation/account/childComps/userData.vue')
          },
          {
            path: '/userManagement/account/changePassword', meta: { title: '修改密码' }, component: () => import('@/views/panelInformation/account/childComps/changePassword.vue')
          },
        ]
      },
      {
        path: '/userManagement/vehicleInformation', meta: { title: '车辆信息' }, component: () => import('@/views/panelInformation/vehicleInformation.vue')
      },
      {
        path: '/userManagement/payInformation/unpaid', meta: { title: '支付详情' }, component: () => import('@/views/panelInformation/payInformation/payInformation.vue'), children: [
          {
            path: '/userManagement/payInformation/unpaid', meta: { title: '未支付' }, component: () => import('@/views/panelInformation/payInformation/childComps/unpaid.vue'),
          },
          {
            path: '/userManagement/payInformation/paymentHistory', meta: { title: '已支付记录' }, component: () => import('@/views/panelInformation/payInformation/childComps/paymentHistory.vue'),
          }
        ]
      },
    ]
  },
  // 切换车辆
  { path: '/userManagement/switchCar', meta: { title: '切换车辆' }, component: () => import('@/views/panelInformation/switchCar.vue') },
  { path: '/userManagement/addCar', meta: { title: '添加车辆' }, component: () => import('@/views/panelInformation/addCar.vue') },
  { path: '/aa', meta: { title: 'aa' }, component: () => import('@/views/aa.vue') },
  // 添加车辆
  // 账号登录
  { path: '/login', meta: { title: '登录' }, component: () => import('@/views/loginAndReg/login') },
  // 手机号登录
  { path: '/mobilePhone', meta: { title: '登录' }, component: () => import('@/views/loginAndReg/mobilePhone') },
  // 注册
  { path: '/registration', meta: { title: '注册' }, component: () => import('@/views/loginAndReg/registration') },
  // 找回密码
  { path: '/retrievePassword', meta: { title: '找回密码' }, component: () => import('@/views/loginAndReg/retrievePassword') },
  //安全学习
  { path: '/userManagement/safeLearning', meta: { title: '安全学习' }, component: () => import('@/views/safeLearning') },
  //准备考试
  { path: '/userManagement/exam', meta: { title: '正在考试' }, component: () => import('@/views/exam') },
  //考试中
  { path: '/userManagement/exam/BeTakingAnExam', meta: { title: '2022年12月安全学习考试' }, component: () => import('@/views/exam/BeTakingAnExam') },
  //成绩单
  { path: '/userManagement/exam/reportCard', meta: { title: '成绩单' }, component: () => import('@/views/exam/reportCard') },
  //预警提示
  {
    path: '/userManagement/earlyWarning', redirect: '/userManagement/earlyWarning/carWarning', meta: { title: '车辆预警' }, component: () => import('@/views/earlyWarning'), children: [
      { path: '/userManagement/earlyWarning/carWarning', meta: { title: '车辆预警' }, component: () => import('@/views/earlyWarning/carWarning') },
      { path: '/userManagement/earlyWarning/certificateWarning', meta: { title: '证书预警' }, component: () => import('@/views/earlyWarning/certificateWarning') },
      { path: '/userManagement/earlyWarning/expenseWarning', meta: { title: '费用预警' }, component: () => import('@/views/earlyWarning/expenseWarning') },
    ]
  },
  { path: '/userManagement/earlyWarning/carWarning/CarWarningDetile', meta: { title: '测试' }, component: () => import('@/views/earlyWarning/carWarning/CarWarningDetile') },
  // { path: '/bbb', meta: { title: '测试' }, component: () => import('@/views/bbb') },
  { path: '/userManagement/earlyWarning/expenseWarning/expenseWarningDetail', meta: { title: '测试' }, component: () => import('@/views/earlyWarning/expenseWarning/expenseWarningDetail') },




]


const scrollTopValue = localStorage.getItem('scrollTopValue')
// console.log(scrollTopValue, 'scrollTopValuescrollTopValuescrollTopValue')


const router = new Router({
  routes,
  mode: 'hash',
  // scrollBehavior: () => ({ y: scrollTopValue?scrollTopValue:0}),
  // scrollBehavior: () => ({ y: 0 }),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
        // console.log(savedPosition, 'savedPosition');
        return savedPosition
    } else {
        return { x: 0, y: 0 }
    }
}

})



router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  const token = getToken();
  if (to.path == '/login' && to.path == '/register' && token == '') {
    Message({
      message: '请先登录！',
      type: "warning",
    })
  } else {
    if (!token) {
      if (to.path == '/userManagement/myCollect' || to.path == '/leaveAMessage') {
        return next('/login')
      }
    }
  }
  if (to.meta.title) {
    document.title = to.meta.title + '-河池志力运输官网';
  }
  next();
});




export default router